import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import DeleteButton from '../buttons/delete/delete'

export default function ModalConfirmDelete(props) {
  const [properties, setProperties] = useState({})

  const handleDelete = async () => {
    let nodeData = props.graphData
    const body = {
      key: 'uuid',
      value: props.selectedObject.uuid
    }
    if (props.selectedObject.type === 'node') {
      await props.sendRequest('node', 'DELETE', body)
      let nodes = props.graphData.nodes.filter(obj => {
        return obj.uuid !== props.selectedObject.uuid
      })
      nodeData.nodes = nodes
      let links = props.graphData.links.filter(obj => {
        if (obj.source.uuid !== props.selectedObject.uuid && obj.target.uuid !== props.selectedObject.uuid) {
          return true
        }
      })
      nodeData.links = links
    } else {
      await props.sendRequest('relationship', 'DELETE', body)
      let links = props.graphData.links.filter(obj => {
        return obj.uuid !== props.selectedObject.uuid
      })
      nodeData.links = links
    }
    props.setGraphData(nodeData)
    props.closeModal()
  }

  useEffect(() => {
    let nodeData
    const token = localStorage.getItem('token')
    async function fetchNode(uuid) {
      try {
        const res = await fetch(`${props.baseUrl}/${props.selectedObject.type}?key=uuid&value=${uuid}`, {
          method: "GET",
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + token
          }
        })
        nodeData = await res.json()
        setProperties(nodeData)
      } catch (error) {
        console.error(error)
      }
      console.log(props.selectedObject)
    }
    if (props.selectedObject) {
      fetchNode(props.selectedObject.uuid)
    }

  }, [props.selectedObject, props.baseUrl])

  return (
    <div className='is-flex is-flex-direction-column is-justify-content-center'>
      <label className='m-2' >Delete {props.selectedObject.type} {properties.name}? </label>
      <div className='is-flex is-flex-direction-row mt-3 is-justify-content-space-evenly'>
        <DeleteButton value={`Delete`} click={handleDelete} />
        <Button value='Cancel' click={() => props.closeModal()} />
      </div>
    </div>
  )

}
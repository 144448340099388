import React, { useEffect, useState, useRef } from 'react'
import './app.css'
import ForceGraph from './components/forceGraph/forceGraph'
import { IoRefreshSharp, IoAddSharp, IoLogOutSharp, IoLogOutOutline } from "react-icons/io5";
import { MdModeEditOutline } from "react-icons/md";
import Modal from './components/modal/modal'
import Button from './components/buttons/confirm/confirm'
import Login from './components/login/loginForm'
import ContextMenu from './components/contextMenu/contextMenu'
import Searchbar from './components/searchbar/searchbar'
import axios from 'axios'
import { BrowserRouter, Routes, Route } from "react-router-dom";
const cfg = require('./config.json')

function App() {
  //const [graphData, setGraphData] = useState(require('./components/forceGraph/forceGraphTest.json'))
  const [graphData, setGraphData] = useState({ "nodes": [], "links": [] })
  const [graphDatafromNodes, setGraphDatafromNodes] = useState({ "nodes": [], "links": [] })
  const [sidepanelVisible, setSidepanel] = useState(true)
  const [showModal, setModal] = useState(false)
  const [editRel, setEditRel] = useState(false)
  const [modalType, setModalType] = useState(null)
  const [selectedObject, setSelectedObject] = useState('')
  const [isLoggedIn, setLoggedIn] = useState(false)
  const [showContextMenu, setContextMenu] = useState(false)
  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 })
  const [contextMenuOptions, setContextMenuOptions] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchArray, setSearchArray] = useState([])
  const [nodeToFocus, setNodeToFocus] = useState('')
  const [configNode, setConfigNode] = useState({})
  const [user, setUser] = useState(null)
  const [sshNode, setNodeSSH] = useState(null)
  const contextMenuRef = useRef()
  let tokenRefreshTimeout

  const toggleSidepanel = () => {
    setSidepanel(!sidepanelVisible)
  }

  const toggleModal = (modalType) => {
    showModal ? setModalType('') : setModalType(modalType)
    setModal(!showModal)
  }

  const openContextMenu = (option) => {
    setContextMenu(true)
    setContextMenuOptions(option)
  }

  const closeContextMenu = () => {
    setContextMenu(false)
    setContextMenuOptions('')
  }

  const updateContextMenuPos = (x, y) => {
    setContextMenuPos({ x: x, y: y })
  }

  const toggleEditRel = () => {
    setEditRel(!editRel)
  }

  const handleSelection = (object) => {
    setSelectedObject(object)
  }

  const toggleLoginScreen = async (newUser) => {
    setUser(newUser)
    setLoggedIn(true)
  }

  const handleNodeFocus = (node) => {
    setNodeToFocus(node)
    setSearchArray([node])
  }

  /* const resetSearch = () => {
    setNodeToFocus('')
    setSearchArray([])
    setSearchName('')
  } */

  const escapeRegex = (string) => {
    // eslint-disable-next-line
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
  }

  const handleSearchName = (event) => {
    const escapedString = escapeRegex(event.target.value)
    setSearchName(event.target.value)
    if (event.target.value.length > 2) {
      const regex = new RegExp(escapedString, 'i')
      let tempArray = graphData.nodes
      //setSearchArray(graphData.nodes)
      //setSearchArray(graphData.nodes.filter(node => regex.test(node.name)))
      setSearchArray(tempArray.filter(node => regex.test(node.name)))
    } else {
      setSearchArray([])
    }
  }

  useEffect(() => {
    const handleOutsideClicks = (event) => {
      if (showContextMenu && contextMenuRef.current && !contextMenuRef.current.contains(event.target)) {
        closeContextMenu()
      }
    }

    document.addEventListener("mousedown", handleOutsideClicks)
    return () => {
      document.removeEventListener("mousedown", handleOutsideClicks)
    }
  }, [showContextMenu])

  async function fetchDatabase() {
    await getConfigNode()
    let nodeData = null
    try {
      const res = await sendRequest('data', 'GET')
      nodeData = res
    } catch (error) {
      console.error(error)
    }
    console.log(nodeData)
    //return nodeData
    if(nodeData) {
      setGraphData(nodeData)
    }else{
      console.log('invalid node data')
    }
  }

  async function showUserNodes() {
    let nodeData
    try {
      const res = await sendRequest('user', 'GET')
      nodeData = res
    } catch (error) {
      console.error(error)
    }
    console.log(nodeData)
    setGraphData(nodeData)
  }

  async function sendRequest(path, method, body) {
    let data
    const token = localStorage.getItem('token')
    let res
    try {
      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'bearer ' + token
      })
      if (body) {
        res = await fetch(`${cfg.base_url}/${path}`, {
          method: method,
          headers: headers,
          body: JSON.stringify(body)
        })
      } else {
        res = await fetch(`${cfg.base_url}/${path}`, {
          method: method,
          headers: headers
        })
      }
      data = await res.json()
    } catch (error) {
      console.error(error)
      console.log(res)
    }
    return data
  }

  const getConfigNode = async () => {
    try {
      const res = await sendRequest('node?key=name&value=graphConfig', 'GET')
      console.log(res)
      if (res) {
        setConfigNode(res)
      }/*  else {
        const body = {
          'label': 'system',
          'name': 'graphConfig',
          'owner': 'system',
          'group': 'system',
          'option': {
            'nodelabels': 'company|person|part|sensor|configuration|device|info'
          }
        }
        const newNode = await sendRequest('node', 'POST', body)
        setConfigNode(newNode)
        const bodyRel = {
          startKey: 'name',
          startValue: 'Start',
          endKey: 'uuid',
          endValue: newNode.uuid,
          relName: 'HASCONFIG'
        }
        await sendRequest('relationship', 'POST', bodyRel)
      } */
    } catch (error) {
      console.error(error)
    }
  }

  const getNodeUpstream = async (ownerID) => {
    getConfigNode()
    let uuid
    if (ownerID != undefined) {
      uuid = ownerID
    }
    else {
      uuid = selectedObject.uuid
    }
    console.log(selectedObject)
    try {
      const res = await sendRequest(`node/upstream?nodeKey=uuid&nodeValue=${uuid}`, 'GET')
      //setGraphDatafromNodes(res.data)
      setGraphData(res)
      console.log(res)
    } catch (error) {
      console.error(error)
    }
  }

  const getNodebyGroup = (newUser) => {
    console.log(newUser)
    axios.post(cfg.base_url + '/node/find', {
      key: 'owner',
      value: newUser.owner
    }).then(res => {
      console.log(res.data)
      if (res.data !== false) {
        getNodeUpstream(res.data.uuid)
      }
    }
    ).catch(error => console.log(error))
  }

  const refresh = async () => {
    if (user.permissionLevel != 'admin') {
      //getNodebyGroup(user)
      getData(user)
    }
    else if (user.permissionLevel === 'admin') {
      fetchDatabase()
      /* let graph = graphData
      let newGraph
      try {
        const res = await sendRequest('data', 'GET')
        newGraph = res
      } catch (error) {
        console.error(error)
      }
      
      let newData = Object.assign({}, newGraph, graph)
      console.log(newGraph)
      console.log(graph)
      console.log(newData)
      setGraphData(newData) */
    }
  }

  const getData = async (newUser) => {
    try {
      const res = await sendRequest(`node/allByUser/${newUser.graphdbuser}`, 'GET')
      setGraphData(res)
    } catch (error) {
      console.log(error)
      refresh()
    }
  }

  async function test() {
    console.log('testing')
    try {
      const res = await sendRequest('test/user', 'GET')
      console.log(res)
    } catch (error) {
      console.log(error)
    }
  }

  function createRefreshTimeout(tokenExpireSeconds) {
    let tokenExpireMs = tokenExpireSeconds * 1000
    let refreshTime = tokenExpireMs - 60000
    tokenRefreshTimeout = setTimeout(() => refreshAccessToken(), refreshTime)
  }

  async function refreshAccessToken() {
    const refreshToken = localStorage.getItem('refreshToken')
    try {
      const body = { refreshToken: refreshToken }
      const res = await sendRequest('auth/token/refresh', 'POST', body)
      if (res.access_token) {
        localStorage.setItem("token", res.access_token)
        createRefreshTimeout(res.expires_in)
      } else {
        logout()
      }
    } catch (error) {
      console.error(error)
      logout()
    }
  }

  function logout() {
    localStorage.removeItem('token')
    localStorage.removeItem('refreshToken')
    setLoggedIn(false)
    setUser(null)
    setGraphData({ "nodes": [], "links": [] })
    clearTimeout(tokenRefreshTimeout)
  }

  const getSSHNode = (e) => {
    //console.log("Hello" + e)
    setNodeSSH(e)
  }

  return (
    <div className=''>
      <div className={isLoggedIn ? 'is-clipped element-hide' : 'is-overlay has-background-light is-flex is-justify-content-center is-align-items-center z-10'} >
        <div className='is-flex box' >
          <Login toggleLogin={toggleLoginScreen} getNode={getData} fetchDatabase={fetchDatabase} sendRequest={sendRequest} createRefreshTimeout={createRefreshTimeout} />
        </div>
      </div>
      <div className={showContextMenu ? 'right-click-menu' : 'element-hide'} style={{ top: contextMenuPos.y, left: contextMenuPos.x }} ref={contextMenuRef} onContextMenu={(event) => event.preventDefault()}>
        <ContextMenu sshNode={sshNode} refresh={refresh} getNodeUpstream={getNodeUpstream} selectedObject={selectedObject} sendRequest={sendRequest} fetchDatabase={fetchDatabase} toggleModal={toggleModal} editMode={toggleEditRel} closeContextMenu={closeContextMenu} contextMenuOptions={contextMenuOptions} />
      </div>
      <div className='graph-view has-background-light' onMouseDownCapture={closeContextMenu}>
        <ForceGraph refresh={refresh} getSSHNode={getSSHNode} searchArray={searchArray} nodeToFocus={nodeToFocus} sendRequest={sendRequest} selectNode={handleSelection} toggleModal={toggleModal} contextMenuPos={updateContextMenuPos} openContextMenu={openContextMenu} closeContextMenu={closeContextMenu} data={graphData} fetchData={fetchDatabase} setGraphData={setGraphData} editRel={editRel} />
      </div>
      <div className={showModal ? 'modal is-active' : 'modal'}>
        <Modal refresh={refresh} sendRequest={sendRequest} configNode={configNode} closeModal={toggleModal} selectedObject={selectedObject} modalType={modalType} fetchData={fetchDatabase} graphData={graphData} setGraphData={setGraphData} user={user} baseUrl={cfg.base_url} />
      </div>
      <div className=''>
        <div className={sidepanelVisible ? 'box is-flex is-flex-direction-row background-transparent top-bar p-2' : 'is-flex-direction-column element-hide'}>
          <div className='is-flex is-flex-direction-row'>
            <div className='is-flex is-flex-grow-0'>
              <Searchbar configNode={configNode} searchArray={searchArray} setSearchName={setSearchName} graphData={graphData} setFocus={handleNodeFocus} searchName={searchName} handleSearchName={handleSearchName} setSearchArray={setSearchArray} setGraphData={setGraphData} />
              {/* <div className=''>
                <Button value='Test' click={() => test('Picture', 'hello')} />
              </div> 
              <div className="m-2" />
              <div className=''>
                <Button value='Close Sidepanel' click={toggleSidepanel} />
              </div>*/}
              <div className="m-2" />
              <div className=''>
                <Button value={<IoAddSharp />} title='New Node' click={() => toggleModal('newNode')} />
              </div>
              <div className="m-2" />
              <div className=''>
                <Button value={<IoRefreshSharp />} title='Refresh' click={() => refresh()} />
              </div>
              <div>
                <Button click={() => showUserNodes()} title='test'></Button>
              </div>
              <div>
                <Button click={() => console.log(graphData)} title='test'></Button>
              </div>
              <div className="m-2" />
              <div className=''>
                <button className={editRel ? 'button is-info' : 'button is-warning'} title='Toggle Relationship Editing' onClick={toggleEditRel}><MdModeEditOutline /></button>
              </div>
              <div className="m-2" />
              <div className=''>
                <button className={'button is-danger'} title='Logout' onClick={logout}><IoLogOutSharp /></button>
              </div>
            </div>
          </div>
          {/* <div>
            <InputForm sendRequest={sendRequest} configNode={configNode} searchArray={searchArray} setSearchName={setSearchName} graphData={graphData} setFocus={handleNodeFocus} searchName={searchName} handleSearchName={handleSearchName} setSearchArray={setSearchArray} setGraphData={setGraphData} />
        </div> */}
          <div className={sidepanelVisible ? 'element-hide' : 'is-flex-grow-0 is-absolute'}>
            <input className='button is-info' type='button' value='>' onClick={toggleSidepanel} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default App

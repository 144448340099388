import React, { useState } from 'react'
import NewWindow from 'react-new-window'
import { JSONEditor } from 'react-json-editor-viewer';

// const EditConfig = (props) => (

//   <NewWindow>
//     <h1>Hi</h1>
//   </NewWindow>
// )

// export default EditConfig

export default function EditConfig(props) {
  const [state, setState] = useState(props.selectedObject['configuration-file'] != undefined ? JSON.parse(props.selectedObject['configuration-file']) : null)
  const [close, setClose] = useState(true);
  const handleGetConfig = async (evt) => {
    evt.preventDefault();
    //alert(props.selectedObject["Uniq-id"])
    const body = {
      uniqID: props.selectedObject["Uniq-id"],
      configuration: state,
      configMethod: "post"
    }
    console.log(body);
    await props.sendRequest('ssh/postConfiguration', 'POST', body).then(res => console.log(res.status))
  }

  const onJsonChange = (key, value, parent, data) => {
    setState(data)
    console.log(parent)
  }

  const onClose = (evt) => {
    evt.preventDefault();
    setClose(false);
  }

  const handleChange = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      console.log("e.target.result", e.target.result);
      setState(JSON.parse(e.target.result));
    };
  };

  return (
    close && (
      <NewWindow closeOnUnmount={close}>
        {
          state != null ?
            <div style={{ marginTop: "0", overflowY: "scroll", height: "100vh", width: "auto", padding: "0" }}>
              <div style={{ padding: "20px", paddingBottom: "70px" }}>
                <JSONEditor
                  data={state}
                  collapsible
                  onChange={onJsonChange}
                  styles={styles}
                />
              </div>
              <div style={{ position: "fixed", bottom: 0, textAlign: "center", width: "100%", paddingBottom: "15px" }}>
                <input accept="application/JSON" type="file" className={'button is-info'} style={{ marginRight: "50px" }} onChange={handleChange}/>
                <button style={{ marginRight: "50px" }} className={'button is-info'} title='Send' onClick={handleGetConfig}>Send</button>
                <button className={'button is-danger'} title='Cancel' onClick={onClose}>Cancel</button>
              </div>
            </div> :
            <h1>Configuration file is missing</h1>
        }
      </NewWindow >
    ))
}

const styles = {
  dualView: {
    display: "flex",
  },
  jsonViewer: {
    borderLeft: "1px dashed white",
    lineHeight: 1.25,
    width: "50%",
    borderLeft: "1px solid lightgrey",
    margin: 10,
  },
  jsonEditor: {
    width: "50%",
    fontSize: 12,
    fontFamily: "Lucida Console, monospace",
    lineHeight: 1.25,
  },
  root: {
    textAlign: "center",
    fontSize: 12,
    fontFamily: "Lucida Console, monospace",
    lineHeight: 1.25,
    /*color: "#3E3D32"*/
  },
  label: {
    color: "DeepPink",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    textAlign: "right",
    width: "150px",
    lineHeight: "15px",
    marginBottom: "10px"
  },
  value: {
    marginLeft: 10,
  },
  row: {
    display: "flex",
  },
  withChildrenLabel: {
    color: "DeepPink",
  },
  select: {
    borderRadius: 3,
    borderColor: "grey",
    backgroundColor: "DimGray",
    color: "khaki",
  },
  input: {
    borderRadius: 3,
    border: "1px solid #272822",
    padding: 2,
    fontFamily: "Lucida Console, monospace",
    fontSize: 12,
    backgroundColor: "gray",
    color: "khaki",
    width: "200%",
    float: "left",
  },
  addButton: {
    cursor: "pointer",
    color: "LightGreen",
    marginLeft: 15,
    fontSize: 12,
  },
  removeButton: {
    cursor: "pointer",
    color: "magenta",
    marginLeft: 15,
    fontSize: 12,
  },
  saveButton: {
    cursor: "pointer",
    color: "green",
    marginLeft: 15,
    fontSize: 12,
  },
  builtin: {
    color: "green",
    fontSize: 12,
  },
  text: {
    color: "black",
    fontSize: 12,
  },
  number: {
    color: "purple",
    fontSize: 12,
  },
  property: {
    color: "DeepPink",
    fontSize: 12,
  },
  collapseIcon: {
    cursor: "pointer",
    fontSize: 10,
    color: "teal",
  },
};


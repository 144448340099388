import React from 'react'

export default function NodeList(props) {

  const listNodes = () => {
    let nodeArray = []
    props.searchArray.forEach(node => {
      nodeArray.push(
        <div className="p-1 m-1 is-clickable" key={node.id} onClick={() => props.setFocus(node)}>
          {node.name}
        </div>
      )
    })
    return nodeArray
  }

  return (
    <div className="is-flex is-flex-direction-column node-list has-background-light">
      {listNodes()}
    </div>
  )
}
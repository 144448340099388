import React, { useState, useEffect } from 'react'
import Button from '../buttons/confirm/confirm'
import DeleteButton from '../buttons/delete/delete'
import TextInput from '../textfields/plainText'
import DeletePropertyButton from '../buttons/delete/deleteProperty'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export default function ModalEditNode(props) {
  //const [nodeLabel, setNodeLabel] = useState('')
  const [propKey, setPropKey] = useState('')
  const [propValue, setPropValue] = useState('')
  const [properties, setProperties] = useState({})

  const handleChangeKey = (event) => {
    setPropKey(event.target.value.toLowerCase())
  }

  const handleChangeValue = (event) => {
    setPropValue(event.target.value)
  }

  const clearFields = () => {
    setPropKey('')
    setPropValue('')
  }

  const deleteProperty = (key) => {
    var newProperties = { ...properties }
    delete newProperties[key]
    setProperties(newProperties)
  }

  const handleDelete = async () => {
    let graphData = props.graphData
    const body = {
      key: 'uuid',
      value: props.selectedObject.uuid
    }
    if (props.selectedObject.type === 'node') {
      await props.sendRequest('node', 'DELETE', body)
      let nodes = props.graphData.nodes.filter(obj => {
        return obj.uuid !== props.selectedObject.uuid
      })
      graphData.nodes = nodes
      let links = props.graphData.links.filter(obj => {
        if (obj.source.uuid !== props.selectedObject.uuid && obj.target.uuid !== props.selectedObject.uuid) {
          return true
        }
      })
      graphData.links = links
    } else {
      await props.sendRequest('relationship', 'DELETE', body)
      let links = props.graphData.links.filter(obj => {
        return obj.uuid !== props.selectedObject.uuid
      })
      graphData.links = links
    }
    let newGraphData = {...graphData}
    props.setGraphData(newGraphData)
    clearFields()
    props.closeModal()
  }

  const handleNewProperty = async () => {
    const body = {
      nodeKey: 'uuid',
      nodeValue: props.selectedObject.uuid,
      propKey: propKey,
      propValue: propValue
    }
    if (propKey && propValue) {
      if (props.selectedObject.type === 'node') {
        await props.sendRequest('node/property', 'POST', body)
      } else {
        await props.sendRequest('relationship/property', 'POST', body)
      }
      setProperties({ ...properties, [propKey]: propValue })
      clearFields()
    }
  }

  useEffect(() => {
    let nodeData
    const token = localStorage.getItem('token')
    async function fetchNode(uuid) {
      try {
        const res = await fetch(`${props.baseUrl}/${props.selectedObject.type}?key=uuid&value=${uuid}`, {
          method: "GET",
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'bearer ' + token
          }
        })
        nodeData = await res.json()
        setProperties(nodeData)
      } catch (error) {
        console.error(error)
      }
      console.log(props.selectedObject)
    }
    if (props.selectedObject) {
      fetchNode(props.selectedObject.uuid)
    }

  }, [props.selectedObject, props.baseUrl])

  const keyNameToUpperCase = (string) => {
    return string.replace(string.charAt(0), string.charAt(0).toUpperCase())
  }

  const listProperties = () => {
    var propArray = []
    for (const [key, value] of Object.entries(properties)) {
      if (props.user.permissionLevel === 'admin' || key !== 'uuid' && key !== 'id' && key !== 'createDate' && key !== 'modifyDate' && key !== 'owner' && key !== 'group') {
        if (key === 'name' || key === 'label' || key === 'uuid' || key === 'id' || key === 'createDate' || key === 'modifyDate' || key === 'owner' || key === 'group') {
          propArray.push(
            <TableRow key={key}>
              <TableCell>{keyNameToUpperCase(key)}</TableCell>
              <TableCell >{value}</TableCell>
            </TableRow>
          )
        } else {
          propArray.push(
            <TableRow key={key}>
              <TableCell>{keyNameToUpperCase(key)}</TableCell>
              <TableCell >{value}</TableCell>
              <TableCell ><DeletePropertyButton deleteProperty={deleteProperty} selectedObject={props.selectedObject} keyName={key} sendRequest={props.sendRequest} /></TableCell>
            </TableRow>
          )
        }
      }
    }
    return propArray
  }

  return (
    <div className='is-flex is-flex-direction-column'>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          {/* <TableHead>
          <TableRow>
            <TableCell>Key</TableCell>
            <TableCell >Value</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead> */}
          <TableBody>

            {listProperties()}

          </TableBody>
        </Table>
      </TableContainer>
      <hr className='mt-2 mb-2' />
      <div className='is-flex is-flex-direction-row'>
        <div className='is-flex is-flex-grow-1'>
          <TextInput placeholder='key' value={propKey} onChange={handleChangeKey} />
        </div>
        <div className='is-flex is-flex-grow-5'>
          <TextInput placeholder='value' value={propValue} onChange={handleChangeValue} />
        </div>
      </div>
      <div className='is-flex is-flex-direction-row mt-3'>
        <Button value='Create/edit Property' click={handleNewProperty} />
        <DeleteButton value={`Delete ${props.selectedObject.type}`} click={handleDelete} />
      </div>
    </div>
  )

}